import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Navbar, Container, NavbarBrand, Nav, NavDropdown } from "react-bootstrap"

const Header = ({ menu }) => {
  return (
    <header className="top-navbar">
      <Navbar expand="lg" bg="light">
        <Container >
          {menu.brand &&
            <NavbarBrand as={Link} to={menu.brand.to}>
              <div className="inner-column">
                <img className="logo" src={menu.brand.image} alt="" />
              </div>
            </NavbarBrand>
          }
          {menu.brand &&
            <NavbarBrand as={Link} to={menu.brand.ta}>
              <div className="inner-column">
                <a href='/nadal23' name="arriba" className="btn btn-lg btn-circle btn-outline-new-white">
                  Nadal 2023
                </a>
              </div>
            </NavbarBrand>
          } 

          <Navbar.Toggle
            data-toggle="collapse"
            data-target="#navbars-rs-food"
            aria-controls="navbars-rs-food"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          {menu.links &&
            <Navbar.Collapse id="navbars-rs-food">
              <Nav className="ml-auto">
                {menu.links.map((link, index) => {
                  if (link.submenu) {
                    return (
                      <NavDropdown title={link.title} key={index}>
                        {link.submenu.map((link, index) =>
                          <NavDropdown.Item as={Link} to={link.to} key={index}>{link.title}</NavDropdown.Item>
                        )}
                      </NavDropdown>
                    )
                  }

                  return (
                    <Nav.Item className={`${link.class || ''} ${link.active && 'active'}`} key={index}>
                      <Nav.Link as={Link} to={link.to}>{link.title}</Nav.Link>
                    </Nav.Item>
                  )
                })}
              </Nav>
            </Navbar.Collapse >
          }
        </Container>
      </Navbar>
    </header>
  )
}

Header.propTypes = {
  menu: PropTypes.shape({
    brand: PropTypes.shape({
      image: PropTypes.string,
      to: PropTypes.string
    }),
    links: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      to: PropTypes.string,
      submenu: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        to: PropTypes.string
      }))
    }))
  })
}

export default Header
