import LoginComponent from "../../components/Login/Login"
import QTPrivado from "./QTPrivado"

const Login = () => {
  return (
    <main className="login">
      <QTPrivado />
      <div className="menu-box">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="heading-title text-center">
                <h2>Zona privada para los asociados en la cuál solo se puede acceder con una clave.<br></br>
                  Si eres asociado y aún no la tienes solicítala a la directiva de la asociación</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="heading-title text-center">
        <LoginComponent />
      </div>
     {/*<div id="qrcode">
<img src="https://www.codigos-qr.com/qr/php/qr_img.php?d=http%3A%2F%2Fhostaleriamoa%C3%B1a.com%2FBarEquis&s=6&e=m" alt="Generador de Códigos QR Codes"/>
<br/><a href="https://www.codigos-qr.com/en/qr-code-generator/" target="_blank" id="qrgenerator">Qr Code Generator</a>
  </div>*/}

    </main>
  )
}

export default Login
