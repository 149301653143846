import Slider from '../../components/Slider/Slider';
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'



const HomeSlider = () => {

  const { dominio } = useParams()  
  const [db, setDb] = useState()
  useEffect(() => {
      fetch(`${process.env.REACT_APP_FAKEAPI_URL_LOCAL}/partners.json`)
          .then(r => r.json())
          .then(data => setDb(data))
  }, [])

  if (!db) return 'loading...'
  const entries = db.filter(i => i.dominio === dominio)
  if (!entries.length) return 'no existe ninguna oferta en esta provincia'
  const asociado = entries[0]
  const imagen1 = asociado.slide1
  const imagen2 = asociado.slide2
  const imagen3 = asociado.slide3
  const imagen4 = asociado.slide4

  const title = `${asociado.nombre}`
  const subtitle = `${asociado.tipo}`
  const slides = [
    {
      title,
      subtitle,
      image: imagen1
    },
    {
      title,
      subtitle,
      image: imagen2
    },
    {
      title,
      subtitle,
      image: imagen3
    },
    {
      title,
      subtitle,
      image: imagen4
    }
  ]

  return <Slider slides={slides} />

}

export default HomeSlider