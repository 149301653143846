
import rifa from '../../../assets/images/noticias/novoSorteo.jpeg'

import React, { useState, useEffect } from 'react'


const Nadal2023 = () => {
  const [db, setDb] = useState()
  useEffect(() => {
    fetch('https://hostaleria-f3540-default-rtdb.europe-west1.firebasedatabase.app//partners.json')
      .then(r => r.json())
      .then(data => setDb(data))
  }, [])

  if (!db) return 'merdiña...'
  const entries1 = db.filter(i => i.id !== 22)
  const entries = entries1.filter(i => i.id !== 62)
  const partners = entries.sort(function () { return Math.random() - 0.5 });

  return (
    <main className="about">
      <div className="about-section-box">
        <div className='title'>
          <h1>SORTEO NADAL 2023</h1>
          <img src={rifa} alt="" className="img-fluid" />
        </div>
        <div className="container">
          <div className="col-md-12">
            <div className="inner-pt">
              


              <div className="row">
                {partners.map(partner =>
                  <div key={partner.id} className="col-sm-12 col-md-4 col-lg-4 ">
                    <h4>{partner.tipo}</h4>
                    <a className="lightbox" href={`/${partner.dominio}`}><h1>{partner.nombre}</h1></a>
                    <p>   </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}



export default Nadal2023